<template>
  <div class="terms-and-conditions">
    <div style="clear: both">
      <p style="margin-top: 0pt; margin-bottom: 8pt">&nbsp;</p>
    </div>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: center;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >Términos y Condiciones de Uso de SND Analytics.</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El uso y acceso a nuestros Servicios (término definido más adelante)
        están sujetos a los siguientes Términos y Condiciones de Uso, por lo que
        si no esta de acuerdo con todas las disposiciones de los Términos, no
        podrá utilizar los Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Los Términos es un documento legal entre la persona moral o física que
        suscribe estos Términos (&ldquo;</span
      ><strong
        ><u><span style="font-family: Garamond">usted</span></u></strong
      ><span style="font-family: Garamond">&rdquo;, &ldquo;</span
      ><strong
        ><u><span style="font-family: Garamond">su</span></u></strong
      ><span style="font-family: Garamond"
        >&rdquo;) y Red Comercial Influyente, S.A.P.I. de C.V. Al utilizar los
        Servicios, usted acepta explícitamente explícitamente y quedar obligado
        por estos Términos y por todos sus documentos accesorios o relacionados,
        según los mismos sean modificados de tiempo en tiempo.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">DEFINICIONES.</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Para todos los efectos legales relacionados con estos Términos:</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Cliente</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la persona física o moral que, en su carácter de
        titular, cotitular o persona autorizada, tenga contratado o llegue a
        contratar los Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Contrase&ntilde;a</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la clave alfanumérica de carácter confidencial de la
        Cuenta correspondiente, generada por SND Analytics de manera aleatoria
        cuya configuración es desconocida para los empleados y funcionarios de
        SND Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Cotización</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la cotización proporcionada por SND Analytics
        respecto a los Servicios solicitados por el Cliente.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Cuenta</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, nombre de la cuenta generada por SND Analytics para que en
        conjunto con la Contrase&ntilde;a el Cliente pueda acceder a los
        Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Cotización Aceptada</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la autorización por parte del Cliente al contenido
        de una Cotización, a través de los medios que SND Analytics designe para
        tales efectos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Dashboard</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la plataforma online disponible a través del Sitio
        que permite el acceso a los Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Datos de Contacto</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significan los datos de contacto establecidos en la sección
        30.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u
        ><span style="font-family: Garamond"
          >Documentos de Contratación</span
        ></u
      ><span style="font-family: Garamond"
        >&rdquo;, significan, en conjunto, la Cotización Aceptada y la Solicitud
        Autorizada.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Fuerza Mayor</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, tendrá el significado que se le asigna en la sección numero
        24.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Información Confidencial</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, tendrá el significado que se le asigna en la sección numero
        12.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Propiedad Industrial</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, tendrá el significado que se le asigna en la sección numero
        13.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">SND Analytics</span></u
      ><span style="font-family: Garamond">&rdquo; o &ldquo;</span
      ><u><span style="font-family: Garamond">nuestro</span></u
      ><span style="font-family: Garamond"
        >&rdquo;: significa la sociedad Red Comercial Influyente, S.A.P.I. de
        C.V.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Servicios</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, tendrá el significado que se le asigna en la sección numero
        2.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Servicios Contratados</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significan los Servicios establecidos en cada uno de los
        Documentos de Contratación que se encuentren vigentes y que conforman el
        alcance de los Servicios que SND Analytics prestará al Cliente, sujeto
        al cumplimiento de las obligaciones del Cliente.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Sistemas</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa el Sitio, el Dashboard y todos aquellos programas o
        sistemas automatizados, desarrollos tecnológicos o de telecomunicación
        que sean propiedad o se encuentren bajo licencia o la la titularidad de
        SND Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Sitio</span></u
      ><span style="font-family: Garamond"
        >&rdquo;: significa la pagina web</span
      ><em><span style="font-family: Garamond">&nbsp;</span></em
      ><a href="http://www.snd.com.mx" style="text-decoration: none"
        ><em
          ><span style="font-family: Garamond; color: #000000"
            >www.snd.com.mx</span
          ></em
        ></a
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Solicitud Autorizada</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa la aprobación por parte del Cliente del contenido de
        una Solicitud de Servicios vinculada a una Cotización Aceptada, a través
        de los medios que SND Analytics designe para tales efectos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Solicitud de Servicios</span></u
      ><span style="font-family: Garamond"
        >&rdquo;, significa el documento en el que SND Analytics hace constar
        las características de los Servicios de una Cotización Aceptada.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><u><span style="font-family: Garamond">Términos</span></u
      ><span style="font-family: Garamond">&rdquo;,</span
      ><strong><span style="font-family: Garamond">&nbsp;</span></strong
      ><span style="font-family: Garamond"
        >significan los presentes Términos y Condiciones de Uso de SND
        Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="2" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">NUESTROS SERVICIOS.</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >A través del Sitio, SND Analytics provee el acceso al Dashboard, el
        cual permite que el Cliente pueda requerir a SND Analytics
        investigaciones o consultas de información o cualquier otro servicio que
        SND Analytics preste o llegase a prestar en el futuro (&ldquo;</span
      ><strong
        ><u><span style="font-family: Garamond">Servicios</span></u></strong
      ><span style="font-family: Garamond"
        >&rdquo;), mediante el uso de su Cuenta y de conformidad con las
        condiciones y alcance de los presentes Términos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente podrá acceder a los Servicios en forma electrónica y remota
        a través de los Sistemas.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics prestará los Servicios siempre que le sean solicitados
        por los medios provistos conforme a los presentes Términos, en los días
        y horas que SND Analytics establezca para tales efectos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="3" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">PRESTACIÓN DE LOS SERVICIOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >En caso de que el Cliente tenga interés en contratar cualquiera de los
        Servicios, deberá solicitar una cotización a SND Analytics. El Cliente
        está de acuerdo en que el staff de SND Analytics lo puede contactar para
        confirmar los detalles de su solicitud.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La Cotización podrá ser denegada o aprobada por el Cliente y, en caso
        de solicitar cambios, el Cliente acepta que los Servicios se</span
      ><span style="font-family: Garamond; font-size: 11pt">&nbsp;</span
      ><span style="font-family: Garamond"
        >volverán a cotizar para contemplar dichos cambios.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Es necesario que exista una Cotización Aceptada para que SND Analytics
        genere la Solicitud de Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Se entenderá que los Servicios han sido efectivamente contratados
        cuando el Cliente (i) proporcione los datos solicitados por SND
        Analytics y (ii) exista una Cotización Aceptada y una Solicitud
        Autorizada, que cumplan con los requerimientos de SND Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics no estará obligada a habilitar el acceso a los Servicios
        Contratados en los siguientes casos:</span
      >
    </p>
    <ol style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Cuando la Cuenta y Contrase&ntilde;a no sean validas.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Cuando los Documentos de Contratación hayan dejado de ser vigentes por
        cualquier motivo, o bien, hayan sido canceladas por SND Analytics a
        solicitud del Cliente.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Por caso de Fuerza Mayor o por cualquier causa ajena al control de SND
        Analytics.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Por falta de pago de cualquier Documento de Contratación.
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente se obliga a asumir los costos por todos los Servicios
        Contratados.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Toda información o material relacionados con los Servicios deberán ser
        presentados en la forma solicitada por SND Analytics.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="4" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">CONEXIÓN DE LOS EQUIPOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Para lograr la conexión a los Servicios, SND Analytics y el Cliente se
        obligan a lo siguiente:</span
      >
    </p>
    <ol style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        El Cliente deberá contar necesariamente con equipo acceso a internet,
        para acceder a la red de internet y así poder ingresar a los Servicios,
        el cual deberá también mantener actualizado de modo que se conserve la
        compatibilidad con los equipos y los Sistemas de SND Analytics.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        SND Analytics permitirá al Cliente conectarse a través de la red de
        internet a los Servicios, mediante el uso de su Cuenta y
        Contrase&ntilde;a.
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="5" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">CUENTA Y CONTRASE&Ntilde;A</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Para todos los efectos legales a que haya lugar, el Cliente
        expresamente reconoce y acepta el carácter personal e intransferible de
        la Contrase&ntilde;a y se obliga a mantenerla de manera confidencial y
        segura.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        line-height: 115%;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Una vez implementado el acceso por parte de SND Analytics, los usuarios
        que el Cliente designe deberán ingresar al Sitio, donde existe una
        sección para el acceso a los Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        line-height: 115%;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente conviene con SND Analytics que cada una de las personas que
        faculte para realizar por su cuenta y orden las operaciones materia de
        estos Términos, tendrán acceso al Dashboard proporcionando la Cuenta y
        Contrase&ntilde;a que SND Analytics tiene establecidas, liberando a SND
        Analytics de cualquier responsabilidad derivada del uso indebido de
        dichos medios de acceso.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >En caso de que el Cliente tenga conocimiento o sospeche de cualquier
        acceso no autorizado o violación de seguridad de su Cuenta, deberá de
        notificarlo inmediatamente mediante los Datos de Contacto. SND Analytics
        hará todos los esfuerzos razonables para responder dentro de un marco de
        tiempo razonable, pero no promete que atenderá dentro de un plazo de
        tiempo en particular o que podrá responder a la notificación.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Así mismo, todo acceso o intento de acceso a cualquier información no
        contemplada dentro de los Servicios Contratados realizado mediante su
        Cuenta, se reputarán como realizados por el Cliente, quien responderá en
        todo caso de dicho acceso y uso, así como de los da&ntilde;os y
        perjuicios y cualquier otra responsabilidad que ello genere.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="6" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">POLÍTICA DE PAGOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.1 Planes de contratación.&nbsp;</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics puede ofrecer los Servicios mediante una suscripción con
        un solo pago o una suscripción mensual con pagos recurrentes, según se
        haya acordado en los Documentos de Contratación.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Un plan de suscripción continuará renovándose hasta que el Cliente o
        SND Analytics lo cancelen.</span
      ><span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.2 Pagos del Cliente</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Una vez efectuado el proceso a que se refiere la sección 3, el Cliente
        se obliga a efectuar el pago de los Servicios Contratados, en las fechas
        y términos indicados en los Documentos de Contratación que
        correspondan.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Salvo otras condiciones de pago pactadas por las partes, el cliente
        deberá enviar por correo electrónico a SND Analytics, el comprobante de
        todos los pagos o depósitos que realice en la cuenta bancaria
        respectiva, para que SND Analytics emita la factura o el recibo de pago
        respectivo.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Si SND Analytics no recibe a tiempo los pagos acordados en los
        Documentos de Contratación: (i) el Cliente adeudara a favor de SND
        Analytics el importe de los cargos no pagados, (ii) el Cliente acepta
        pagar todos los montos adeudados en el momento en que le sean
        solicitados, y (iii)</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >SND Analytics suspenderá el uso y acceso a los Servicios, pudiendo dar
        de baja la Cuenta del Cliente, sin responsabilidad para SND
        Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond">6.3 Métodos de pago.</span></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Para el pago de los Servicios Contratados, el Cliente deberá elegir un
        método de pago de las opciones que SND Analytics le proporcione. A menos
        que se indique lo contrario, los precios de los Servicios son en pesos
        mexicanos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.3.1 Cargos a tarjeta bancaria del Cliente.</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente autoriza a SND Analytics a realizar cargos a su cuenta, a la
        tarjeta bancaria que el Cliente se&ntilde;ale para tales efectos. La
        falta de terminación de los presentes Términos por parte del Cliente o
        el uso continuo de los Servicios reitera que SND Analytics esta
        autorizado a realizar cargos a la tarjeta bancaria del Cliente.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente reconoce que es titular de la tarjeta bancaria que
        proporcione a SND Analytics o que está autorizado para hacer uso de la
        misma, por lo que usted será responsable por todos los cargos que SND
        Analytics efectúe.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Siempre que el Cliente o SND Analytics no suspendan la utilización de
        este tipo de pago, usted entiende que esta pre-autorización es valida
        hasta la terminación de los Términos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.3.2 Depósitos o transferencias</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Si el Cliente realiza pagos a favor de SND Analytics a través de
        depósitos bancarios o transferencias bancarias, los hará en las cuentas
        que SND Analytics le indique. Es responsabilidad del Cliente verificar
        que la información proporcionada al banco en cuestión es correcta de
        manera que la transacción se pueda completar. Asimismo, el Cliente es
        responsable de adjuntar la información de referencia indicada por SND
        Analytics con el fin de que el pago sea correctamente identificado por
        SND Analytics y aplicado a la cuenta del Cliente. SND Analytics no se
        hace responsable si la transacción no puede completarse exitosamente
        debido a errores incurridos por el Cliente o el intermediario elegido
        por el Cliente.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.4 Cambios de precios</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics se reserva el derecho de cambiar los precios de cualquier
        Servicio Contratado, previa notificación al Cliente. Cualquier cambio al
        precio de los Servicios Contratados no tendrá efecto inmediato, pero se
        implementará al comienzo del próximo periodo de suscripción posterior a
        la fecha de cambio de precio. Sujeto a la ley aplicable, se entiende que
        el Cliente acepta el nuevo precio al continuar usando los Servicios
        después de que el cambio de precio entre en vigor. Si usted no esta de
        acuerdo con un cambio de precio, tiene derecho a rechazar el cambio
        anulando la suscripción con SND Analytics antes de que entre en vigor el
        nuevo precio.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond">6.5 Reclamaciones</span></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente acuerda indicar cualquier reclamación respecto a cualquier
        cargo realizado a su cuenta, escribiendo a SND Analytics al correo
        electrónico establecido en los Datos de Contacto, dentro de un plazo de
        10 (diez) días hábiles contados a partir de la aplicación del cargo
        correspondiente; de lo contrario el Cliente renuncia a dicha reclamación
        y el cargo será definitivo y no estará sujeto a reclamación
        alguna.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong
        ><span style="font-family: Garamond"
          >6.6 Datos de facturación</span
        ></strong
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La responsabilidad de proporcionar información de contacto y
        facturación precisa y completa derivada de cualquier Servicio Contratado
        recae Únicamente en usted. SND Analytics no es responsable de ninguna
        inexactitud u omisión por su parte durante el procedimiento de
        pago.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">6.7 Impuestos</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Si aplicase cualquier impuesto a las transacciones relacionadas con
        estos Términos, el Cliente deberá pagar dichos impuestos asegurándose de
        que SND Analytics reciba el monto completo facturado sin ninguna
        deducción o de lo contrario SND Analytics realizará dicho pago con cargo
        al Cliente. El Cliente es el Único responsable de las sanciones que se
        deriven de la falta de pago de los impuestos aplicables a los Servicios
        Contratados.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="7" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">CANCELACIÓN DE LOS SERVICIOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente podrá cancelar los Servicios Contratados antes del final del
        periodo de la suscripción vigente en ese momento.</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >La cancelación entrará en vigor el día siguiente al Ultimo día del
        periodo de suscripción actual y deberá ser solicitada mediante los Datos
        de Contacto.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="8" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">POLÍTICA DE REEMBOLSOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Mediante la aceptación de los presentes Términos, el Cliente acepta y
        reconoce que debido a la naturaleza de los Servicios, todas las compras
        y ventas son finales. En la medida permitida por la ley aplicable, los
        pagos no son reembolsables y no se otorgarán reembolsos ni créditos por
        los periodos de la suscripción utilizados parcialmente. Sin embargo, SND
        Analytics se reserva el derecho de atender a su entera discreción cada
        solicitud de reembolso. Si está buscando un reembolso debido a un error
        o mal funcionamiento de los Servicios, comuníquese mediante los Datos de
      </span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="9" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 33.01pt;
          text-align: justify;
          line-height: normal;
          padding-left: 2.99pt;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond"
          >TEMPORALIDAD EN LA PRESTACIÓN DE LOS SERVICIOS</span
        >
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Los Servicios podrán presentar interrupciones en casos de
        mantenimiento. En este supuesto, SND Analytics deberá informar al
        Cliente de este proceso con una anticipación no menor a 1 (un) día
        previo a su realización.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics no tendrá ninguna responsabilidad en virtud de los
        Términos ni asegura la continuidad de los Servicios por problemas de
        conexión a la red de internet ni en casos de Fuerza Mayor.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics podrá fijar libremente las bases, requisitos y
        condiciones de operación de los Servicios, los días y el horario de
        operación, así como el limite de las programaciones diarias.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="10" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond"
          >MODIFICACIONES EN LOS SERVICIOS</span
        >
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics se reserva el derecho de actualizar e implementar
        mecanismos o mejoras en sus Sistemas para brindar correctamente los
        Servicios. Usted reconoce y acepta que la forma y naturaleza de los
        Servicios que SND Analytics le provee pueden cambiar de vez en cuando
        sin previo aviso y que SND Analytics puede, a su entera discreción, ya
        sea de forma temporal o permanente, dejar de ofrecer los Servicios o
        cualquier funcionalidad al Interior de éstos sin notificación
        previa.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="11" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">POLÍTICAS DE PRIVACIDAD</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente entiende y acepta que SND Analytics utilizará la información
        proporcionada por el Cliente, en forma confidencial y de conformidad con
        el Aviso de Privacidad que SND Analytics le ha hecho conocer en términos
        de la legislación aplicable y que en este acto el Cliente manifiesta
        conocer y estar de acuerdo con el mismo.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Toda la información y datos proporcionados a SND Analytics por el
        Cliente con anterioridad o posterioridad a la prestación de los
        Servicios objeto de estos Términos, serán detentados y mantenidos
        Únicamente por SND Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="12" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">INFORMACIÓN CONFIDENCIAL</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&ldquo;</span
      ><strong
        ><u
          ><span style="font-family: Garamond"
            >Información Confidencial</span
          ></u
        ></strong
      ><span style="font-family: Garamond"
        >&rdquo; significa cualquier información revelada a un Cliente por SND
        Analytics o a SND Analytics por el Cliente, ya sea directa o
        indirectamente, por escrito, oralmente o mediante la inspección de
        objetos tangibles, incluyendo cualquier modelo, formato o documento de
        referencia que el Cliente proporcione a SND Analytics para fines de
        compilación de la información, las solicitudes de información que el
        Cliente haga a través del Servicio, los resultados proporcionados por
        SND Analytics en cumplimiento de los presentes Términos, las
        Cotizaciones y Solicitudes de Servicios y cualquier otra información
        intercambiada entre las partes que sea distinta a la información que se
        puede obtener: (i) públicamente y que es de dominio público antes del
        momento de la divulgación a usted por SND Analytics (ii) se vuelve
        conocida públicamente y puesta a disposición después de la revelación a
        usted por SND Analytics que no sea a través de sus acciones o falta de
        acciones, o (iii) esté en su posesión, restricciones de
        confidencialidad, antes del momento de la divulgación por SND Analytics
        como muestran los archivos y registros.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente no podrá, en ningún momento, (i) publicar, vender,
        licenciar, transferir o poner a disposición de cualquier persona o
        entidad ninguna Información Confidencial (salvo en el caso de que sus
        empleados y agentes tengan una necesidad legitima de conocer dicha
        información y se encuentren obligados por escrito a mantener
        confidencialidad), o (ii) usar, reproducir o copiar cualquier
        Información Confidencial, excepto cuando sea necesario en relación con
        la finalidad para la que dicha Información Confidencial es revelada a
        usted y de conformidad con estos Términos. El Cliente se compromete a
        tomar todas las medidas para proteger la confidencialidad y evitar la
        divulgación y el uso no autorizado de la Información
        Confidencial.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente podrá revelar Información Confidencial si es requerido por
        la ley, siempre que se notifique a SND Analytics de forma pronta y por
        escrito de tal requisito previo a la divulgación y a la obtención de una
        orden que proteja la información de su divulgación publica, y que,
        ademas dicha divulgación se limite a lo estrictamente necesario para
        cumplir con el requisito legal.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Toda la Información Confidencial permanecerá como propiedad de SND
        Analytics y todos los documentos, medios electrónicos y otros objetos
        tangibles que contengan o están relacionados con la Información
        Confidencial deberán ser entregados inmediatamente a SND Analytics
        cuando lo solicite y en el momento en que se dé por terminado estos
        Términos.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="13" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">PROPIEDAD INDUSTRIAL</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Todas las marcas, nombres comerciales, logotipos, nombres de dominio y
        cualquier forma de propiedad industrial reconocida o que lleguen a
        reconocer las leyes correspondientes, sobre la que SND Analytics sea
        titular o posea derechos similares (en lo sucesivo, &ldquo;</span
      ><strong
        ><u
          ><span style="font-family: Garamond">Propiedad Industrial</span></u
        ></strong
      ><span style="font-family: Garamond"
        >&rdquo;) son propiedad exclusiva de SND Analytics</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond">o sus licenciantes.&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Los Términos no te otorgan derecho alguno para usar la Propiedad
        Industrial ya sea para uso comercial o no comercial.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="14" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span style="font-family: Garamond">DERECHOS DE AUTOR</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La titularidad y derechos de autor de los Servicios incluyen las
        imágenes, fotografías, textos, aplicaciones y subprogramas incorporados
        en los Servicios.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Todo reporte, así como su estructura, será considerado como propiedad
        de SND Analytics. En los casos en que SND Analytics utilice información
        propiedad de cualquier tercero, la o las fuentes serán mencionadas en
        dichos reportes, en cumplimiento por parte de SND Analytics, de las
        disposiciones legales aplicables.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="15" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span style="font-family: Garamond">LIMITES DE LOS SERVICIOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics otorga al Cliente un permiso limitado, no exclusivo,
        personal, no comercial y revocable para hacer uso de los Servicios y
        permanecerá vigente mientras los Términos se encuentren vigentes. Esta
        licencia es revocable en cualquier momento y por cualquier motivo o sin
        motivo alguno.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente manifiesta su conformidad para utilizar los Servicios
        Únicamente de la forma en que esta expresamente permitida por los
        Términos. A excepción de los derechos que expresamente se otorgan en los
        Términos, SND Analytics no otorga al Cliente ningún derecho, titulo o
        interés en los Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        line-height: normal;
        widows: 0;
        orphans: 0;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics se reserva todos los derechos que no le haya concedido al
        Cliente de forma expresa.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="16" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">RESPONSABILIDADES DEL CLIENTE</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente declara y garantiza que toda la información que proporciona
        a SND Analytics es verdadera y acuerda mantener la integridad de dicha
        información. En ningún momento SND Analytics será responsable de la
        veracidad ni vigencia de los datos provistos por el Cliente.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics no se hace responsable por las decisiones que pueda
        adoptar el Cliente a partir de la información derivada de los Servicios.
        Los reportes o datos derivados de los Servicios de SND Analytics son de
        carácter meramente informativos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente se compromete a administrar de forma adecuada la información
        obtenida de los Servicios, siendo de su absoluta responsabilidad la
        distribución de dicha información.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente se obliga a utilizar los Servicios y todo el contenido e
        información que se derive de los mismos conforme a lo establecido en las
        leyes aplicables y en los presentes Términos. Asimismo, se obliga hacer
        un uso adecuado de la información proporcionada por SND Analytics y a no
        emplearla para realizar actividades ilícitas o constitutivas de delito,
        que atenten contra los derechos de terceros o que infrinjan la
        regulación sobre propiedad intelectual e industrial, o cualesquiera
        otras normas del ordenamiento jurídico aplicable, así como ayudar a
        otros a hacerlo o dar instrucciones o los medios para tales
        propósitos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Es responsabilidad del Cliente informar a SND Analytics respecto a
        cualquier modificación que se produzca en la relación de usuarios
        designados para la utilización de los Servicios. SND Analytics no será
        responsable por la utilización de los Servicios en caso de que el
        Cliente no informe oportunamente acerca de estos cambios. Para este fin,
        el Cliente podrá informar los cambios en la relación de los usuarios
        mediante carta simple o correo electrónico.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La transmisión de la información que SND Analytics realice será
        validada por los Sistemas de SND Analytics y le será devuelto un acuse
        de recibo con la validación correspondiente, ya sea de cargado o no
        satisfactoriamente, por lo que los registros de las operaciones a que se
        refieren los presentes Términos que aparezcan en los sistemas de SND
        Analytics y en los comprobantes que de las mismas expidan, tendrán pleno
        valor probatorio y fuerza legal como constancia de que operaron los
        Servicios.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Queda totalmente prohibido que el Cliente modifique, descompile o
        intente de algún otro modo extraer nuestro codigo fuente o crear
        trabajos derivados de él, sin nuestro permiso por escrito (o un permiso
        en virtud de una licencia de código abierto). Cualquier intento de
        utilizar programas automatizados, bots, llamadas a base de datos,
        capturas de pantalla, recolección humana de información o cualquier otro
        medio de recolección de contenido, datos e información con el propósito
        de aplicar ingeniería inversa a nuestros Sistemas con fines comerciales,
        queda estrictamente prohibido. El Cliente tampoco puede subir virus o
        códigos maliciosos, ni realizar actividades que puedan impedir,
        sobrecarga o afectar el correcto funcionamiento de nuestros Servicios, o
        afectar su aspecto.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="17" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">INDEMNIZACIONES</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente se compromete a indemnizar a SND Analytics, sus sociedades
        controladoras, sus subsidiarias, sus empleados, sus directivos, sus
        socios, sus licenciantes, sus consultores, sus contratistas, sus
        agentes, sus abogados o sus proveedores de servicios, de cualquier y
        todo reclamo, responsabilidades, costos, da&ntilde;os, perdidas y gastos
        de cualquier tipo, incluyendo honorarios razonables de abogados, que
        surjan o estén relacionados con (1) su incumplimiento en todo o en parte
        de los Términos; (2) cualquier actividad en la que el Cliente</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >participe en o a través de los Servicios y; (3) si infringe cualquier
        ley o los derechos de un tercero.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="18" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span style="font-family: Garamond">LIMITACIÓN DE RESPONSABILIDAD</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Bajo ninguna circunstancia SND Analytics será responsable ante usted
        por la perdida de ganancias, ingresos o información, ni por da&ntilde;os
        consecuentes, especiales, indirectos, ejemplares, punitivos o
        incidentales que surjan a raíz de estos Términos o los Servicios o en
        relación con ellos, incluso si se nos advirtió sobre la posibilidad de
        que ocurran dichos da&ntilde;os.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente acepta que ni SND Analytics ni ninguna de sus empresas
        controladoras, filiales ni subsidiarias, serán responsables, de manera
        enunciativa mas no limitativa, por:</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Operar o controlar la red internet, de su interrupción, mal
        funcionamiento o cualquier situación que se derive de la red de internet
        que estuviese fuera del control de SND Analytics.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Toda responsabilidad y riesgo que derive del uso que el Cliente le dé a
        los Servicios, Cuentas, Contrase&ntilde;as o al internet mismo.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Los da&ntilde;os y perjuicios que se deriven del mal uso que el Cliente
        haga de la red o servicio de acceso a internet.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Por el uso o destino que le de el Cliente, a cualquiera de los Servicios
        referidos, localizados, operados o programados desde el Sitio.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Por el uso o destino que le dé el Cliente a la información o reportes
        entregados por SND Analytics.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        La presencia de virus, o la ausencia de otros elementos en sus sistemas
        o sistemas de terceros, que puedan producir alteraciones en el sistema
        informático (software y hardware) o en los documentos electrónicos y
        archivos almacenados, propiedad o de acceso del Cliente.
      </li>
      <li
        style="
          margin-left: 31.25pt;
          text-align: justify;
          line-height: normal;
          padding-left: 4.75pt;
          font-family: Garamond;
          font-size: 12pt;
          background-color: #ffffff;
        "
      >
        Las demás que se establezcan en los Términos.
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >En virtud de lo anterior, el Cliente se obliga a sacar en paz y a salvo
        a SND Analytics o a quienes sus derechos represente, respecto de
        cualquier responsabilidad por los da&ntilde;os y perjuicios de cualquier
        naturaleza que puedan originarse, incluyendo honorarios de
        abogados.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="19" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">RENUNCIA DE GARANTÍAS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >El Cliente expresamente acepta que los Servicios se prestaran
        &ldquo;tal cual&rdquo;, sin garantía de ningún tipo, expresa o
        implícita, y que su uso es bajo su propio riesgo. Sin perjuicio de lo
        anterior, SND Analytics se libera, en la extensión máxima permitida por
        la ley, de ofrecer cualquier garantía, expresa o implícita, incluyendo
        cualesquier (i) garantías de comercialización o idoneidad para un
        propósito particular; (ii) garantías en contra de la violación de
        propiedad intelectual o derechos de autor de terceros; (iii) garantías
        relacionadas con demoras, interrupciones, errores u omisiones en los
        Servicios de SND Analytics o cualquier parte del Sitio; (iv) garantías
        relativas a la precisión o veracidad de los datos y otras garantías
        relacionadas con el rendimiento, la falta de desempe&ntilde;o, u otros
        actos u omisiones de SND Analytics. Asimismo, y sin perjuicio de lo
        anterior, no hay garantía de que los Servicios o el Sitio de SND
        Analytics cumplan con sus necesidades o requisitos, o requisitos de
        cualquier otra persona. El Servicio puede verse limitado por muchos
        factores, incluyendo riesgos inherentes de internet.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Ningún consejo o información, ya sea oral o por escrito que el Cliente
        haya obtenido de nuestra parte creará ninguna garantía en nombre de SND
        Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >En caso de insatisfacción o problemas con nuestros Servicios el Cliente
        acepta que su Único y exclusivo recurso es dejar de usar los
        Servicios.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="20" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">ALCANCE PROBATORIO</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La información e instrucciones que el Cliente transmita o comunique a
        SND Analytics al efectuar sus operaciones, así como los comprobantes
        emitidos y transmitidos por los Sistemas de SND Analytics, tendrán pleno
        valor probatorio y fuerza legal para acreditar la operación realizada,
        su naturaleza, así como las características y alcance de sus
        instrucciones, y por tanto, serán obligatorias y vinculantes para el
        Cliente y encuadradas en los términos y condiciones de los modelos de
        solicitudes o contratos que SND Analytics habitualmente utiliza para
        instrumentar tales actos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="21" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">RESCISIÓN</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics podrá rescindir los Términos mediante aviso simple y sin
        responsabilidad, en los casos de incumplimiento por parte del Cliente a
        cualesquiera de las obligaciones que asume conforme a los presentes
        Términos siempre que dicho incumplimiento no sea subsanado dentro de los
        5 (cinco) días hábiles siguientes a aquel en que SND Analytics notifique
        la Cliente sobre el incumplimiento respectivo.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >La terminación de los presentes Términos conforme a lo establecido en
        esta cláusula no exime al Cliente del pago de las sumas que estuvieran
        pendientes de pago.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="22" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span style="font-family: Garamond"
          >MODIFICACIONES DE LOS TÉRMINOS</span
        >
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics se reserva el derecho de hacer cambios a los presentes
        Términos según se requiera. De ser así le proporcionaremos un aviso al
        Cliente ya sea solicitando su aceptación dentro del Sitio o mediante el
        correo electrónico que tenga registrado en su Cuenta.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >Si el Cliente no está de acuerdo con las modificaciones realizadas
        deberá suspender de inmediato el uso de los Servicios, de lo contrario
        el uso continuo de los Servicios constituirá su aceptación a los
        Términos y su voluntad expresa para recibir los Servicios, por lo tanto,
        el Cliente se obliga a leer cualquier aviso con cuidado.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="23" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">SUBSISTENCIA</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Ninguna renuncia, de cualesquiera de las partes, por un incumplimiento
        de cualquier disposición de los presentes Términos será tomada o
        considerada como una renuncia por cualquier incumplimiento posterior de
        la misma o como una renuncia a la misma. Si alguna disposición de los
        Términos es invalida, o inaplicable por cualquier razón, dicha invalidez
        no afectará el resto de los Términos, y dicha disposición invalida o
        inaplicable, será sustituida por una nueva disposición.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="24" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">FUERZA MAYOR</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics no tendrá ninguna responsabilidad en virtud de los
        Términos, en relación con cualquier fallo o retraso en el cumplimiento
        de sus obligaciones a causa de huelgas, escasez, disturbios, actos de
        terrorismo, incendios, inundaciones, tormentas, explosiones, terremotos
        o cualquier desastre natural, cortes de internet, virus computacionales,
        pandemias, actos de guerra, acciones gubernamentales, o cualquier otro
        caso fortuito y no imputable a</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond">SND Analytics (&ldquo;</span
      ><strong
        ><u><span style="font-family: Garamond">Fuerza Mayor</span></u></strong
      ><span style="font-family: Garamond">&rdquo;).</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="25" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">INDEPENDENCIA DE LAS PARTES</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">Las partes son</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >independientes y nada en estos Términos podrá interpretarse para crear,
        probar o implicar ningún tipo de agencia, relación laboral, sociedad o
        empresa conjunta entre las partes. Las partes expresamente convienen y
        reconocen que no existe relación laboral alguna entre el personal
        empleado por el Cliente y SND Analytics y que cualquier obligación
        derivada de la relación entre SND Analytics y su personal será Única y
        exclusivamente responsabilidad de SND Analytics.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="26" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">CESIÓN DE DERECHOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Usted no puede ceder, transferir, sublicenciar o delegar ningún derecho
        u obligación en virtud de los Términos sin el previo consentimiento por
        escrito de SND Analytics. Cualquier cesión o transferencia, o intento de
        transferencia o cesión, en violación de esta sección será
        nula.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">SND Analytics</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >y sus cesionarios subsecuentes pueden ceder los Términos, en todo o en
        parte, o cualquiera de sus derechos y obligaciones, o delegar cualquiera
        de sus funciones, en el marco de los presentes Términos, a cualquier
        otra persona. SND Analytics se reserva todos los derechos no
        expresamente concedidos en</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >los Términos y renuncia a todas las licencias implícitas.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="27" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">ENCABEZADOS</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Los encabezados utilizados en el presente documento son para fines de
        referencia y no afectarán en ningún sentido los Términos. El termino
        &ldquo;incluyendo&rdquo; es un término que significa la ampliación
        &ldquo;incluyendo, sin limitación&rdquo; y no denota exclusividad.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="28" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">FIRMA ELECTRÓNICA EFECTIVA</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Los Términos es un contrato electrónico que establece las condiciones
        jurídicamente vinculantes respecto al uso de los Servicios. Usted indica
        que acepta los Términos y todas las estipulaciones contenidas o
        referidos en estos Términos marcando positivamente la casilla
        &ldquo;Aseguro que la información que he proporcionado es verdadera y
        acepto los Términos y Condiciones del Servicio así como del Aviso de
        Privacidad&rdquo; o una sintaxis similar y haciendo click en el botón
        &ldquo;Confirmar Registro&rdquo;. Esta acción crea una firma electrónica
        que tiene la misma fuerza y efecto legal que una firma manuscrita por lo
        que usted acepta que los Términos le han sido entregados de forma
        electrónica. Usted puede, si así lo desea, imprimir o almacenar en sus
        registros una copia de estos Términos.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >En los términos del articulo 93 del Código de Comercio, el uso de los
        medios de identificación previstos en estos Términos, es decir, la
        Cuenta y Contrase&ntilde;a, para realizar las operaciones autorizadas a
        través del Sitio, sustituirán el nombre y la firma autógrafa del Cliente
        y producirán los mismos efectos que las leyes otorgan a los documentos
        correspondientes, por lo que tendrán el mismo valor probatorio.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <a name="_eqx0p97enk9l"></a
      ><span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <a name="_1ffk95oyv83d"></a
      ><span style="font-family: Garamond"
        >Para la contratación de nuevos Servicios, las Partes manifiestan su
        absoluto consentimiento para que los Documentos de Contratación o
        cualquier otro acuerdo o documento relacionado con los Servicios puedan
        ser celebrados a través de medios electrónicos. Así mismo, el Cliente
        acepta y reconoce que en caso de que su firma autógrafa sea sustituida
        por una firma de carácter digital, tendrá el mismo valor probatorio y
        fuerza legal que su firma manuscrita.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="29" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">COMUNICACIONES</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >SND Analytics puede darle avisos generales por medio de mensajes
        incluidos en el Sitio o por correo electrónico a la dirección de correo
        electrónico que usted proporcione a SND Analytics al registrarse. Es su
        responsabilidad asegurarse de que la información de contacto que
        proporcione a SND Analytics se mantenga actualizada y correcta en todo
        momento. Todo aviso que usted desee hacer a SND Analytics</span
      ><span style="font-family: Garamond">&nbsp;&nbsp;</span
      ><span style="font-family: Garamond"
        >deberá hacerlo mediante los Datos de Contacto, o bien dirigiéndose por
        escrito con su ejecutivo comercial.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Salvo que SND Analytics especifique lo contrario, usted acepta dirigir
        todas sus comunicaciones relacionadas con los Servicios, o con su
        participación en los mismos, directamente a SND Analytics y no con otra
        persona.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <ol start="30" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
        "
      >
        <span style="font-family: Garamond">DATOS DE CONTACTO</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond"
        >En caso de cualquier duda o aclaración, ponemos a su disposición
        nuestros datos de contacto:</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ul style="margin: 0pt; padding-left: 0pt" type="disc">
      <li
        style="
          text-indent: 21.25pt;
          text-align: justify;
          line-height: normal;
          font-family: serif;
          font-size: 12pt;
          font-weight: bold;
          list-style-position: inside;
        "
      >
        <span
          style="
            width: 2.5pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
          >&nbsp;&nbsp;</span
        ><span style="font-family: Garamond; font-weight: normal"
          >Correo electrónico: contacto@snd.com.mx&nbsp;</span
        >
      </li>
      <li
        style="
          text-indent: 21.25pt;
          text-align: justify;
          line-height: normal;
          font-family: serif;
          font-size: 12pt;
          font-weight: bold;
          list-style-position: inside;
        "
      >
        <span
          style="
            width: 2.5pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
          >&nbsp;&nbsp;</span
        ><span style="font-family: Garamond; font-weight: normal"
          >Teléfono: 8134035486</span
        >
      </li>
      <li
        style="
          text-indent: 21.25pt;
          text-align: justify;
          line-height: normal;
          font-family: serif;
          font-size: 12pt;
          font-weight: bold;
          list-style-position: inside;
        "
      >
        <span
          style="
            width: 2.5pt;
            font: 7pt 'Times New Roman';
            display: inline-block;
          "
          >&nbsp;&nbsp;</span
        ><span style="font-family: Garamond; font-weight: normal"
          >Domicilio: José F. Muguerza 2702, colonia Lomas de Chepevera,
          Monterrey, Nuevo León, México,</span
        ><span style="font-family: Garamond; font-weight: normal"
          >&nbsp;&nbsp;&nbsp;&nbsp;</span
        ><span style="font-family: Garamond; font-weight: normal"
          >C. P. 64030.</span
        >
      </li>
    </ul>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="31" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">CONVENIO TOTAL</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Los Términos constituyen el acuerdo completo y el entendimiento entre
        las partes involucradas con respecto a la materia contenida en este
        documento y sustituye todos los anteriores acuerdos, propuestas y
        comunicaciones (por cualquier medio, incluyendo todas las instrucciones,
        anuncios, mensajes y políticas), escritas y orales, entre usted y SND
        Analytics con respecto a la materia contenida en este documento.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >En caso de que usted sea una persona moral, la persona física que
        suscribe los Términos en su representación declara bajo protesta de
        decir verdad, que cuenta con las facultades necesarias para obligarse en
        lo personal, u obligar a su representada, a través de lo que estos
        Términos establecen y de todo aquello que de la presente aceptación se
        derive.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >En caso de que exista un conflicto entre estos Términos, el Aviso de
        Privacidad o cualquier Documento de Contratación, acuerdo, contrato o
        cualquier otro documento en el que se hagan constar derechos y
        obligaciones de SND Analytics y el Cliente relacionados con el presente
        instrumento, lo establecido en los Términos prevalecerá.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <ol start="32" style="margin: 0pt; padding-left: 0pt" type="1">
      <li
        style="
          margin-left: 36pt;
          text-align: justify;
          line-height: normal;
          font-family: Arial;
          font-size: 12pt;
          font-weight: bold;
          background-color: #ffffff;
        "
      >
        <span style="font-family: Garamond">JURISDICCIÓN Y COMPETENCIA</span>
      </li>
    </ol>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <strong><span style="font-family: Garamond">&nbsp;</span></strong>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Cualquier disputa en referencia o relacionada con los Términos o entre
        las partes involucradas, deberá regirse bajo las leyes federales
        aplicables y someterse a la competencia de los tribunales de Monterrey,
        Nuevo León, México, renunciando el Cliente a cualquier otro fuero o
        jurisdicción que pudiera corresponderle en razón de su domicilio
        presente o futuro.&nbsp;</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Cualquier reclamo en contra de SND Analytics que surja de estos
        Términos deberá efectuarse de forma individual y no podrá consolidarse
        con cualquier otra demanda o con cualquier reclamo de cualquier otra
        persona física o moral.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Si usted no está de acuerdo con estos Términos no utilice los
        Servicios. Cada vez que usted utilice los Servicios se aplicaran los
        Términos más recientes. Acorde a esto, cada vez que usted utilice los
        Servicios, deberá revisar la fecha de los Términos y Condiciones del
        Servicio (la cual se mostrará en este documento) y revisar cualquier
        modificación desde la versión anterior. Los Términos y Condiciones de
        los Servicios actuales estarán disponibles en todo momento en el
        Sitio.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Las partes acuerdan que todo acto que se realice en relación o derivado
        de los presentes Términos estarán a lo dispuesto por los artículos 89,
        90, 91, 93 y 94 del Código de Comercio, o en aquellos que en su momento
        los sustituyan o sean aplicables.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond"
        >Fecha de Ultima actualización: 21 de diciembre de 2021.</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
        background-color: #ffffff;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: justify;
        line-height: normal;
        font-size: 12pt;
      "
    >
      <span style="font-family: Garamond">&nbsp;</span>
    </p>
    <div style="clear: both">
      <p style="margin-top: 0pt; margin-bottom: 8pt">&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
}
</script>

<style>
.terms-and-conditions {
  padding: 0 1rem;
}
</style>
